<template>
    <div class="pt-5">
        <Wizard
        navigable-tabs
        scrollable-tabs
        :nextButton="{
          text: 'Next',
          hideIcon: false, // default false but selected for sample
          hideText: false, // default false but selected for sample
        }"
        :doneButton="{
          hideText: true,
          hideIcon: true
        }"
        :custom-tabs="[
          {
            title: 'Select Wallet',
          },
          {
            title: 'Select Staking',
          },
          {
            title: 'Review and confirm',
          }
        ]"
        :beforeChange="onTabBeforeChange"
        @change="onChangeCurrentTab"
      >
        <div v-if="currentTabIndex === 0">
            <Wallet @select="selectWallet" />
        </div>
        <div v-if="currentTabIndex === 1">
            <Staking @select="selectStaking" />
        </div>
        <div v-if="currentTabIndex === 2">
            <div class="row">
              <div class="col-12 col-md-6 mb-3">
                <CardWallet :wallet="form.wallet"/>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <CardStaking :membership="form.membership"/>
              </div>
              <div class="col-12 text-right mt-4">
                <ButtonAction :loading="loading" loadingTx="Creating Staking" block="true" lg="true" title="Create Staking" @click="onSubmit"/>
              </div>
            </div>
        </div>

      </Wizard>
    </div>
</template>
<script>
import 'form-wizard-vue3/dist/form-wizard-vue3.css'
import Wizard from 'form-wizard-vue3'
import 'form-wizard-vue3/dist/form-wizard-vue3.css'
import Wallet from './Wallet.vue'
import Staking from './Staking.vue'
import CardWallet from '../../wallet/CardWallet.vue'
import CardStaking from './CardStaking.vue'
import { mapActions } from 'vuex'
export default {
    components: {
        Wizard,
        Wallet,
        Staking,
        CardWallet,
        CardStaking
    },
    data() {
        return {
          currentTabIndex: 0,
          form: {
            wallet: null,
            membership: null
          },
          loading: false
        };
    },
    methods: {
      ...mapActions('staking', ['storeStaking']),
    onChangeCurrentTab(index) {
      console.log('ULTIMO', index)
      this.currentTabIndex = index;
    },
    onTabBeforeChange() {
      if (this.currentTabIndex === 0) {
        console.log('First Tab');
      }
      console.log('All Tabs');
    },
    selectWallet(wallet) {
      this.form.wallet = wallet
    },
    selectStaking(staking) {
      this.form.membership = staking
    },
    onSubmit() {
      this.loading = true
      this.storeStaking(this.form).then(() => {
        this.loading = false
        openNotification()
        this.$router.push({ name: 'Staking' })
      })
    }
  },
}
</script>
<style>
    .form-wizard-vue .fw-body-list .fw-list-progress {
        position: absolute !important;
        height: 2px !important;
        background: #6481E7 !important;
        top: 50% !important;
        left: 80% !important;
    }
</style>