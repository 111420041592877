<template>
    <div>
        <div class="row">
            <div class="col-sm-6 col-md-4 mb-3" v-for="membership in memberships" :key="membership.id">
                <CardPackage :isActive="cardActive.id" :membership="membership" @click="selectStaking(membership)" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import CardPackage from './CardStaking.vue'
export default {
    components: {
        CardPackage
    },
    data () {
        return {
            loading: false,
            cardActive: {
                id: null
            }
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('membership', ['getMemberships']),
        getData() {
            this.loading = true
            this.getMemberships().then(() => {
                this.loading = false
            })
        },
        selectStaking(membership) {
            this.cardActive = membership
            this.$emit('select', membership)
        }
    },
    computed: {
        ...mapState('membership', ['memberships'])
    }
}
</script>