<template>
    <div>
        <b-card class="text-center" :class="isActive == membership.id ? 'bg-active' : '' ">

            <b-avatar :src="membership.image" size="7rem"></b-avatar>

            <h3 class="mt-4">
                {{ membership.name }}
            </h3>
            <div class="mt-3 row">
                <div class="col-6 text-left pt-3">
                    Plan price
                </div>
                <div class="col-6 plan-price text-right">
                    <span>${{membership.price}}</span>/usd
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props: {
        membership: {
            required: true
        },
        isActive: {
            default: false
        }
    },
    data () {
        return {
            modalEdit: false,
            modalDelete: false,
            loading: false
        }
    },
    methods: {
        ...mapActions('membership', ['updateMembership', 'destroyMembership']),
        confirmeDelete (id) {
            this.loading = true
            this.destroyMembership(id).then(() => {
                this.loading = false
            })
        }
    },
}
</script>
<style scoped>
    .plan-name{
        font-weight: 500;
        font-size: 25px;
        line-height: 32px;
    }
    .plan-price{
        font-weight: 300;
        font-size: 18px;
        line-height: 40px;
    }
    .plan-price span {
        font-weight: 700;
        font-size: 18px;
        line-height: 48px;
    }
    .bg-active{
        border: 3px solid #1A16BA !important;
        border-radius: 35px !important;
        transition: .3s all;
    }
</style>